$primary: #337ab7;
$headerBarHeight: 40px;
$spacer: 1rem !default;

.headerBar {
    height: $headerBarHeight;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-image: -moz-linear-gradient(top,#135a97,#337ab7);
    background-image: -webkit-linear-gradient(top,#135a97,#337ab7); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image:         linear-gradient(to bottom,#135a97,#337ab7); /* Chrome 26, Firefox 16+, IE 10+, Opera */
    z-index: 10;
    color: #eeeeee;
    padding: 10px 30px;
    white-space: nowrap;
    overflow: hidden;
}

body > .container {
    margin-top: (2 * $headerBarHeight);
}

.row {
    margin-top: ($spacer * 1.5) !important;
}

.headerBar a, headerBar a:visited, headerBar a:hover {
    color: #eeeeee;
}

.essLink {
    position: fixed;
    top: 0;
    right: 0;
    width: 210px;
    height: 130px;
    padding: 15px;
    background-image: -moz-linear-gradient(top,#135a97, #337ab7 15px,#539ad7);
    background-image: -webkit-linear-gradient(top,#135a97,#337ab7); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image:         linear-gradient(to bottom,#135a97,#337ab7); /* Chrome 26, Firefox 16+, IE 10+, Opera */
    z-index: 10;
    border-radius: 0 0 0 10px;
}

.essLink img {
    background-color: rgba(255,255,255,0.7);
    border-radius: 5px;
}

.bottomNav {
    margin-bottom: 0;
    border-radius: 0;
    background-image: -moz-linear-gradient(top,#135a97,#337ab7);
    background-image: -webkit-linear-gradient(top,#135a97,#337ab7); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image:         linear-gradient(to bottom,#135a97,#337ab7); /* Chrome 26, Firefox 16+, IE 10+, Opera */
}

.bottomNav.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
}

.topnav {
    .nav-pills > li > a {
        border-radius: 4px;
        width: 120px;

        &:hover {
            background-color: #eee;
        }
    }

    .nav-pills > li.active > a {
        background-image: -moz-linear-gradient(top, #337ab7, #539ad7);
        background-image: -webkit-linear-gradient(top, #135a97, #337ab7); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
        background-image: linear-gradient(to bottom, #135a97, #337ab7); /* Chrome 26, Firefox 16+, IE 10+, Opera */
        color: white;
    }
}

.vife_icon {
    text-align: center;
    font-size: 2em;
    height: 90px;
    padding: 10px;
}

.vife_iconText {
    display: block;
    margin-top: 2ex;
    /* margin: 20px 20px 10px; */
    font-size: 16px;
}

a.vife_more {
    display: block;
    color: #337ab7;
    background-position: center center;
    background-repeat: no-repeat;
    height: 30px;
}

a.vife_werkzeuge {
    display: block;
    color: #666666;
    margin-top: 10px; 
    background-position: center center;
    background-repeat: no-repeat;
    height: 30px;
}

a.vife_werkzeuge2 {
    display: block;
    color: #666666;
    background-position: center center;
    background-repeat: no-repeat;
    height: 30px;
}

.vife_teamMember, .vife_alumni{
    img {
      width: 250px;
      height: 250px;
      filter: grayscale(100%);
      object-fit: cover;
      border-radius: 300px;
    }
} 

.vife_project, .tool {
    img {
        max-height: 250px;
        object-fit: contain;
    }
}

.external_tool {
    background: repeating-linear-gradient(
        150deg,
        #f5f5f5,
        #f5f5f5 20px,
        #f9f9f9 20px,
        #f9f9f9 40px
      );
}

p.lead {
    text-align: justify;
}

.vife_contact_icon  {
    margin-right: 10px;
}

.byline {
    font-style: italic;
    text-align: right;
}

figure {
    text-align: center;
    figurecaption {
        font-size: small;
        font-style: italic;
    }
}

.gsoc {
    h2 {
        text-align: center;
        margin-top: 2ex;
    }
    .gsoc-idea-head {
        p {
            margin-bottom: 0;
        }
        margin-bottom: $spacer;
    }
}

.orcid_icon {
    background-image: url('/assets/img/ORCID-iD_icon_unauth_BW_vector.svg');
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
    vertical-align: middle;
}

.blog {
    .card-img-top {
        width: 100%;
        max-height:15vh;
        object-fit: cover;
    }
}
